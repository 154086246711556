#previevImagesLogoBanner{
    display: flex!important;
    flex-direction: column!important;
    position: relative!important;
    align-items: center!important;
    margin-top: 1rem!important;
}
#previevImagesBanner{
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-radius: 10px;
    position: relative;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}
#previevImagesLogo{
    width: 100px!important;;
    height: 100px!important;
    object-fit: cover!important;
    border-radius: 100px;
    position: relative!important;
    margin-top: -2rem!important;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.073)!important;
   
}
#show-more-btn{
    background-color: var(--color1);
    padding: 7px 10px;
    color: var(--text);
    border-radius: 6px;
    border: none;
    margin-top: 15px;
}

@media (max-width: 700px){
    #previevImagesBanner{
        width: 100%;
        height: 130px;
        object-fit: cover;
        border-radius: 10px;
        position: relative;
    }
    #previevImagesLogo{
        width: 70px!important;;
        height: 70px!important;
       
    }
}