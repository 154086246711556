.ContentNumeros{
    width: 100%;
    gap: 2rem;
    display: flex;
    flex-direction: column;
    border-radius:10px ;
    padding: 70px  3%;
    overflow: hidden;


}

.DataGrapNumber{
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: space-around;
}
.generar{
    background-color: var(--color2);
    color: var(--blanco);
    padding:  10px 10px;
    border: none;
    border-radius: 8px;
    font-size: 16px;
}
.activeBtn{
  background-color: var(--bg-color);
  color: var(--blanco);
  padding: 10px ;
  width: 5rem;
  height: 5rem!important;
  border-radius: 10px;
  height: 2.3rem;
  border: 1px solid var(--bg-color);
}
.activeBtn:hover{
  
    background: linear-gradient(0deg, var(--color4) 0%, var(--color2) 100%);
}

.activeBtn:hover strong{
    background: linear-gradient(0deg, var(--color4) 0%, var(--color2) 100%);
}
.descativeBtn {
    background: linear-gradient(0deg, var(--color4) 0%, var(--color2) 100%);
    color: var(--blanco);
   padding: 10px ;
   width: 5rem;
   height: 5rem!important;
   border-radius: 10px;
   border: none;
   height: 2.3rem;
}

.selectedBtn{
    background: linear-gradient(0deg, var(--color4) 0%, var(--color2) 100%);
    color: var(--blanco);
   padding: 10px ;
   width: 5rem;
   height: 5rem!important;
   border-radius: 10px;
   border: none;
   height: 2.3rem;
}

.activeBtn strong{
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    padding: 20px;
    background-color: var(--bg);
    border-radius: 100%;
    border: 3px solid var(--color4);
}
.selectedBtn strong{
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    padding: 20px;
    background: linear-gradient(0deg, var(--color4) 0%, var(--color2) 100%);
    border-radius: 100%;
    border: 3px solid #ffffff37;
}
.descativeBtn strong{
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    padding: 20px;
    background: linear-gradient(0deg, var(--color4) 0%, var(--color2) 100%);
    border-radius: 100%;
    border: 3px solid #ffffff37;
}

.openModalBtn{
    background: linear-gradient(0deg, var(--color4) 0%, var(--color2) 100%);
    color: var(--blanco);
    border-radius: 100%;
    position: fixed;
    bottom: 40px;
    right: 0;
    left: 75%;
    color: var(--blanco);
    padding: 25px;
    border-radius: 10px;
    width: 15rem;
    height:2.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    font-size: 17px;
    z-index: 2;
    cursor: pointer;
    box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.196);
   
}
.overlay-cart {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.333); 
    z-index: 999; 
    display: flex;
    justify-content: right;
}
.modal-cart {
    top: 0;
    background-color: var(--bg);
    width: 65vh;
    height: 100vh;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    border-radius: 10px 0px 0px 10px;
    border: none;
    
}

.modal-content-cart {
    width: 100%;
    overflow-y: scroll;
    min-height: 70vh;
    padding: 10px;
   
    
}

.deFLex{
    display: flex;
    justify-content: space-between;
    padding: 15px 20px;
    background-color: var(--color4);
    color: var(--blanco);
    align-items: center;
}
.deFLex h4{
    color: var(--blanco);
    font-size: 17px;
    font-weight: 400;
}
.deFLex button{
    background-color: transparent;
    border: none;
    color: var(--blanco);
    cursor: pointer;
    font-size: 17px;
    
}
.contenModal{
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    overflow-y: scroll;
    max-height: 60vh;
}
.contenModal::-webkit-scrollbar {
    width: 5px;
    height: 5px;

    cursor: pointer;
    background-color: transparent;

}

.contenModal::-webkit-scrollbar-thumb {
    background-color: var(--color2);
    border-radius: 10px;
   
}
.modal-send-form{
    padding: 20px;
    display: flex;
    flex-direction: column; 
    gap: 1rem;
}
.modal-send-form input {
    width: 100%;
    padding: 20px;
    background-color: var(--bg-color);
    border: none;
    height: 2rem;
    border-radius: 10px;
    color: var(--blanco);
}
@media (max-width: 1024px) {
    .ContentNumeros{
        margin-top: 0;
        border-radius: 0;
        box-shadow: none;
        padding: 20px  5%;
        padding-bottom:   20vh;
}
    .openModalBtn{
        position: fixed;
        bottom: 0;
        right: 0;
        left: 0;
        width: 100%;
        border-radius: 0;
}
.activeBtn:hover {
    background: var(--bg-color); 
}
    
    .activeBtn:hover strong{
        background: var(--bg-color);
    }
    .activeBtn{
        width: 4rem;
        height: 4rem;

    }
    .descativeBtn{
        width: 4rem;
        height: 4rem;
    }
    .selectedBtn{
        width: 4rem;
        height: 4rem;
    }

    .selectedBtn strong{
        padding: 10px;
    }
    .descativeBtn strong{
        padding: 10px;
    }
    .activeBtn strong{
        padding: 10px;
    }

    .generar{
        padding:  6px 10px;
        font-size: 14px;
        margin-top: -1rem;
    }
    .modal-cart {
        border-radius: 0 ;
        
    }
}