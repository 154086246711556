
.detail-contain{
    width: 100%;
    gap: 2rem;
    display: flex;
    flex-direction: column;
    border-radius:15px ;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.955) 0%, rgb(0, 0, 0) 100%);
    overflow: hidden;
}
.detail{
    width: 100%;
    padding: 5% 9%;
    gap: 2rem;
    min-height: 100vh;
    position: relative;
    background-color: var(--bg-color);
 
}





.detailText{
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 2rem;
    position: relative;
    background: linear-gradient(0deg, rgb(0, 0, 0) 0%, rgb(0, 0, 0) 100%);
    height: 80vh;
   
}
.detailNum{
    display: flex;
    flex-direction: column;
    width: 100%;
}
.imgContain{
    width: 100%;
    height: 60vh;
    box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.09);
    border-radius:10px ;
    position: relative;


}
.imgContain {
    position: relative; /* Para que el gradiente se posicione correctamente sobre la imagen */
    width: 100%;
    height: 100%;
    border-radius: 15px;
}

.imgContain img {
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 15px;
    object-fit: cover;
}

.imgContain::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height:110%;
    border-radius: 15px;
    background: linear-gradient(to top, rgb(0, 0, 0), rgba(0, 0, 0, 0) 80%); /* Gradiente de negro a transparente */
    z-index: 1; /* Coloca el gradiente por encima de la imagen */
}

.imgContain img {
    position: relative;
    z-index: 0; /* La imagen se mantiene debajo del gradiente */
}

.imgDetail{
    display: flex;
    gap: 1rem;

}

.imgGrid{
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
}
.imgGrid img{
    width: 40vh;
    height: 28.7vh;
    object-fit: cover;
    border-radius: 10px;
    cursor: pointer;
}
.img {
    width: 100%;
    height: 60vh;
    object-fit: cover;
    border-radius: 10px;
    cursor: pointer;
}

.custom-modal {
    background: rgba(0, 0, 0, 0.8);
   
  }
  
  .custom-modal img {
    width: 100%;
    height: 100%;
 
  }
  
  iframe {
    width: 100%;
    height: 30vh;
    border-radius: 10px;
   
}
hr{
  
    border: 0.3px solid #00000013;
    
}

.share{
    background-color: var(--gris);
    border: none;
    border: none;
    font-size: 17px;
    color: var(--text-color2);
    padding: 8px 9px;
    border-radius: 100%;
    cursor: pointer;
}
.favoritos-btn{
    background-color: var(--gris);
    border: none;
    border: none;
    font-size: 17px;
    color: var(--text-color2);
    padding: 8px 9px;
    border-radius: 100%;
    cursor: pointer;
}

.deFlexDetail{
    display: flex;
    justify-content: space-between;
    padding: 20px 2%;
    align-items: center;
    gap: 2rem;
    position: absolute;
    z-index: 3;
    width: 82%;
}
.deFLexIcon{
    display: flex;
    justify-content: space-between;
    padding: 20px 0%;
    align-items: center;
    gap: 2rem;
}
.title {
    font-size: 30px;
    font-weight: 600;
    color: var(--blanco);
}
.detail-contain p{
    color: var(--text-color2);
    white-space: pre-line;
    max-height: 8rem;
    min-height: 8rem;
    
    overflow-y: scroll;
}
.detail-contain p::-webkit-scrollbar{
    width: 7px;
    height: 7px;
    cursor: pointer;
 }
 
 .detail-contain p::-webkit-scrollbar-thumb{
     background-color: var(--color1);
     width: 10px;
     border-radius:20px ;
     cursor: pointer;
 }

.deColumn{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.price {
    font-size: 25px;
    color: var(--color1);
    font-weight: 700;
    margin-top: 1rem;
  
}
.precioTachadoDetail {
    text-decoration: line-through;
    color: var(--text-color2)!important;
    font-size: 25px;
    font-weight: 700;
    margin-top: 1rem;
  }
.titlecoment{
    color: var(--text-color2);
  
}

.wpp{
   background-color: rgb(30, 184, 30);
   color: var(--blanco);
   padding: 10px 20px;
   display: flex;
   justify-content: center;
   align-items: center;
   border: none;
   font-size: 17px;
   cursor: pointer;
   box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.074);
   border-radius: 10px;
   gap: 10px;
   width: auto;
}
.wpp img{
    width: 25px;
}
.textDetail{
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1rem;
    padding: 10px 3%;
    border-radius:10px ;
    height: 100%;
    width: 100%;
    z-index: 4;
    margin-top: -10rem;
}
.textDetail .wpp{
    padding: 5px 10px;
    border-radius: 6px;
}
.deFlexGoTobt{
    display: flex;
    align-items: center;
    gap: 1rem;
}
.margin{
    margin: 5rem 3% 0 ;
}
.back{
    background-color: var(--gris);
    border: none;
    padding: 7px 9px;
    border-radius: 100%;
    color: var(--text-color2);
    font-size: 17px;
   
}
.textDetail h3{
    display: flex;
    gap: 10px;
    align-items: center;
    color: var(--blanco);
    font-weight: 600;
    background: linear-gradient(0deg, var(--color4) 0%, var(--color2) 100%);
    width: 18rem;
    font-size: 17px;
    justify-content: center;
    text-align: center;
    border-radius: 4px;
    padding: 5px 6px;
}
.textDetail h4{
    color: var(--blanco);
    font-size: 25px;
    font-weight: 600;
}
.deFLexBuet{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.containGana{
    width: 100%;
    gap: 2rem;
    border-radius:10px ;
    text-align: center;
    padding: 0px  3%;
}
.ganadorText{
    width: 19rem;
    display: flex;
    text-align: center;
    flex-direction: column;
    justify-content: space-around;
    gap: 10px;
    background-color: var(--bg-color);
    box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.09);
    padding: 20px;
    border-radius: 10px;
    margin-right: 1rem;
}
.ganadorText strong{
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--color1);
    background: linear-gradient(0deg, var(--color4) 0%, var(--color2) 100%);
    padding: 10px;
    border-radius: 10px;
    color: var(--blanco);
    font-weight: 600;
}
.puesto{
    border: 1px solid var(--color1);
    background: linear-gradient(0deg, var(--color4) 0%, var(--color2) 100%);
    padding: 10px;
    border-radius: 10px;
    color: var(--blanco);
}
@media (max-width: 1024px) {
    .detailText{
       
        height: auto;
       
    }
    .deFLexIcon{
        gap: 1rem;
    }
    
    .detail-contain p{
        max-height: 100%;
        min-height:  100%;
    }
    .deFlexDetail{
        z-index: 3;
        top: 10px;
        width: 100%;
        padding: 0 4%;
}
.share{
    background-color: transparent;
    color: var(--blanco);
}
.back{
    background-color: transparent;
    color: var(--blanco);
    font-size: 23px;
}
    .imgContain{
        display: flex;
        height: 30vh;
        overflow: hidden;
        border-radius: 0;
}
   .imgContain img{
      display: flex;
      width: 100%;
      border-radius: 0;
    }
   
    .detail{
        min-height: 100vh;
        padding:  0;
    }
    .detail-contain{
        flex-direction: column;
        gap: 0;
        box-shadow: none;
        border-radius: 0;
}

    
    .textDetail{
        padding: 20px;
        gap: 10px;
        height: auto;
        margin-top: -6rem;
        width: 100%;
        
    }
    .title{
        font-size:22px;
        font-weight: 700;
    }
    .textDetail h3{
        font-size:12px;
        width: 60%;
    }
    .wpp{
        padding: 10px 20px;
        font-size: 14px;
    }
    .wpp img{
       width: 20px;

    }
   
    .ganadorText{
        width: 15rem;
        height: auto;
        gap: 10px;
        padding: 15px;
    }
    .ganadorText strong{
        padding: 7px;
        font-size: 14px;
    }
    .puesto{
        padding: 7px;
        font-size: 14px;
    }
    .margin{
        margin: 20px 5%;
    }
    .containGana{
        padding: 10px 5%;
    }
    .textDetail h4{
        font-size: 18px;
    }
    .deFlexGoTobt{
        gap: 10px;
    }
}
