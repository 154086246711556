.Terminos{
    padding: 20px;
    display: flex;
    justify-content: center;
   
}
.Terminos strong{
    color: var(--text-color2);
    cursor: pointer;
    font-weight: 400;
}





.modal-Terminos{
    top: 0;
    background-color: var(--blanco);
    height: 80vh;
    width: 80vh;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    border-radius: 10px ;
    border: none;
 
}

.overlay-Terminos{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.333); 
    z-index: 999; 
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5%;
}

.modal-Terminos-contain{
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    text-align: center;
    border: 1px solid var(--gris2);
    height: 100%;
    border-radius: 10px;
    overflow: hidden;
   
}


.modal-Terminos-contain button{
    border-radius: 10px;
    border: none;
    background-color: var(--color1);
    padding: 10px 20px;
    color: var(--blanco);
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}


.modal-Terminos-contain img{
    width: 100%;
    height: 70vh;
    object-fit: cover;
    border: 2px solid var(--blanco);
}

@media (max-width: 900px){
   
   
    .modal-dev{
        
        height: 50vh;
     
    }
    .modal-Terminos{
        height: 60vh;
    }
   
    .modal-Terminos-contain img{
         height: 50vh;
    }
    .modal-Terminos{
        width: 100vh;
     
    }
  
    
    
}