* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  transition: all 0.2s linear;
}
:root {
  --text-color: #000;
  --text-color2: #5c5959;
  --bg-color: #100d2d;
  --bg: #181442;
  --bg2: #1f177c;
  --gris: #fafafa;
  --gris2: #f3eeee;
  --color1: #181442;
  --color2: #201bac;
  --color3: rgba(31, 135, 233, 0.274);
  --color4: #2951e2;
  --blanco: #fff;
  --input: #c1bfbf;
  --loading: #e2296a3c;
  --text: #fff;
  --th: #f2f2f28b;
  --nav: #3f1d76;
}


h2,
h3,
h4,
h5 {
  color: var(--text-color);
}
a {
  text-decoration: none;
}

body {
  background-color: var(--bg-color);
}
a,
.detail-contain img,
p,
.modalNav,
#SwiperSlide-scroll-products,
.categoriasInputs input,
.cardProdcutSelected,
.cardProdcutmasVendido,
.cartIconFixed,.openModalBtn {
  opacity: 0;
  animation: slideFromLeft 1s ease-in-out forwards;
  -webkit-animation: slideFromLeft 1s ease-in-out forwards;
}

.modaldashboard {
  opacity: 0;
  animation: slideUp 0.4s ease-in-out forwards;
  -webkit-animation: slideUp 0.4s ease-in-out forwards;
}

.iconDark {
  position: fixed;
  z-index: 100;
  background-color: var(--text-color);
  border: none;
  right: 0px;
  bottom: 100px;
  padding: 10px;
  border-radius: 7px 0px 0 7px;
  width: 2.3rem;
}

.darkmode-icon {
  color: var(--gris);
  font-size: 17px;
}
@keyframes slideFromLeft {
  from {
    opacity: 0;
    transform: translateX(-20%);
    -webkit-transform: translateX(-20%);
    -moz-transform: translateX(-20%);
    -ms-transform: translateX(-20%);
    -o-transform: translateX(-20%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideFromCenter {
  from {
    opacity: 0;
  }
  to {
    opacity: 10;
    transform: translateY(0%);
    -webkit-transform: translateY(0%);
    -moz-transform: translateY(0%);
    -ms-transform: translateY(0%);
    -o-transform: translateY(0%);
  }
}

@keyframes slideUp {
  from {
    opacity: 0;
  }
  to {
    opacity: 10;
    transform: translateY(-3%);
    -webkit-transform: translateY(-3%);
    -moz-transform: translateY(-3%);
    -ms-transform: translateY(-3%);
    -o-transform: translateY(-3%);
  }
}
