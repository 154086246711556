.Content{
    margin: 0px 10%;
    min-height: 100vh;
    background-color: black;
}

.titleSection{
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid var(--bg2);
    padding-bottom: 2rem;
    align-items: center;
}

.titleSection h2{
    color: var(--blanco);
    font-weight: 600;
    font-size: 30px;

}
.Content .titleSection{
    padding: 20px 3%;
    border: none;
}
.DataGrap{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 2rem;
    padding: 20px 3%;
}
.rifaCard{
    width: 30%;
    height: 14rem;
    border-radius: 15px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.111);
    background-color: var(--bg);
    margin-top: 1rem;
    overflow: hidden;
}

.rifaCard img{
    width: 100%;
    height: 55%;
    object-fit: cover;
}

.cardRifaText{
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.cardRifaText h3{
    color: var(--blanco);
    font-weight: 500;
    font-size: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 25ch;
}
.cardRifaText h4{
    color: var(--blanco);
    font-weight: 500;
    font-size: 16px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 25ch;
}
.deFlexPrix{
    display: flex;
    justify-content: space-between;
}
.cardRifaText strong{
    color: var(--blanco);
    font-weight: 500;
    font-size: 13px;
    background: linear-gradient(0deg, var(--color4) 0%, var(--color2) 100%);
    padding: 4px;
    border-radius: 6px;
    width: 10rem;
    text-align: center;
}
.iconTitle{
    color: var(--blanco);
    background-color: var(--color4);
    padding: 3px;
    font-size: 12px;
    border-radius: 3px;
}
@media (max-width: 900px) {
    .rifaCard{
        width: 100%;
        height: 13rem;
        margin-top: 1rem;
        overflow: hidden;
    }
    
.rifaCard img{
    width: 100%;
    height: 7rem;
    object-fit: cover;
}
    .Content{
        padding: 30px 7%;
        margin: 0px 0%;
    }
    .titleSection h2{
        font-weight: 700;
        font-size: 18px;
    
    }
    .titleSection{
      
        padding-bottom: 1rem;
    }
    .DataGrap{
       padding: 0%;
    }
}