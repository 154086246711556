.pageDetail{
    background-color: var(--blanco);
   
}

@media (max-width: 700px){
    .pageDetail{
        background-color: var(--color1);
       
    }
}