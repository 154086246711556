.profileContain{
    padding: 10px 5%;
    background-color: var(--bg);
    display: flex;
    flex-direction: column;
    gap: 2rem;
    z-index: 9900000000!important;
    
}
.profileContain img{
    width: 5rem;
    height: 5rem;
    object-fit: cover;
    border-radius: 100%;
    margin-top: -3rem;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.127);
    background-color: var(--bg);
}
.profileContain h2{
    font-weight: 500;
    color: var(--blanco);
}
.socials{
    display: flex;
    gap: 2rem;
}
.socials a{
    color: var(--blanco);
    font-size: 18px;
}
.profileText{
    display: flex;
    flex-direction: column;
    gap: 10px;
    
}
.profileText a{
    color: var(--blanco);
    border-bottom: 1px solid var(--bg);
    padding-bottom: 10px;
}
@media (max-width: 700px) {
    .profileContain{
        padding: 10px 7%;
       
    }
}