.search-overlay{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.333); 
    z-index: 999; 
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5%;
}
.btnSearch{
    padding: 9px 15px;
    border-radius: 6px;
    border: none;
    color: var(--blanco);
    background-color: var(--color2);
    font-size: 16px;
}
.search-modal{
    top: 0;
    background-color: var(--bg);
    width: 65vh;
    height: 80vh;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    border-radius: 10px ;
    border: none;
}
.cardSearch{
    display: flex;
    flex-direction: column;
    gap: 6px;
    background-color: var(--bg2);
    padding: 10px;
    border-radius: 10px;
    color: var(--blanco);
    margin-bottom: 1rem;
}
.cardSearch strong{
    font-style: 16px;
}
.cardSearch:hover{
    background-color: var(--color2);

}
.headerModal{
    background-color: var(--color2);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding:15px 20px;
}
.headerModal button{
    background-color: transparent;
    font-weight: 600;
    font-size: 20px;
    border: none;
    color: var(--blanco);
}
.headerModal h3{
    background-color: transparent;
    font-weight: 600;
    font-size: 18px;
    border: none;
    color: var(--blanco);
}

.deFLexSearch{
    display: flex;
    align-items: center;
    background-color: var(--blanco);
    border-radius: 10rem;
    padding:2px;
    overflow: hidden;
    margin:  2rem;
}
.deFLexSearch input{
    width: 100%;
    border-radius: 10rem;
    border: none;
    margin: 0;
    padding:10px 20px;
    background-color: transparent;
}
.deFLexSearch button{
    border-radius: 10rem;
    border: none;
    padding:  12px;
    width: 9rem;
    margin: 0;
    font-size: 14px;
    background-color: var(--color2);
}
.searchCards{
    overflow-y: scroll;
    height: 50vh;
    padding: 0 2rem;

}

.searchCards::-webkit-scrollbar {
    width: 5px;
    height: 5px;

    cursor: pointer;
    background-color: transparent;

}

.searchCards::-webkit-scrollbar-thumb {
    background-color: var(--color2);
    border-radius: 10px;
   
}
@media (max-width: 900px){
    .deFLexSearch{
        margin:  1rem;
    }
    .deFLexSearch input{
        
        padding:7px 20px;
    }
    .deFLexSearch button{
        padding:  10px;
        width: 9rem;
        font-size: 12px;
    }
    .searchCards{
        overflow-y: scroll;
        height: 80vh;
        padding: 0 1rem;
    
    }
   
    .btnSearch{
        padding: 8px 12px;
        border-radius: 6px;
        border: none;
        color: var(--blanco);
        background-color: var(--color2);
        font-size: 13px;
    }
    .headerModal h3{
        font-size: 16px;
    }
    .headerModal button{
        font-size: 18px;
    }
    .cardSearch{
        font-size: 13px;
    }
    .search-overlay{
        padding: 0%;
    }
    .search-modal{
        top: 0;
        background-color: var(--bg);
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        border-radius: 0 ;
        border: none;
}
}