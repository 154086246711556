.previevImagesLogoBanner{
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: center;
    margin-top: 1rem;
}
.previevImages{
    width: 100%;
    height:270px;
    object-fit: cover;
    border-radius: 10px;
    position: relative;
}

@media (max-width: 900px) {
    .previevImages{
        width: 100%;
        height:200px;
        object-fit: cover;
        border-radius: 10px;
        position: relative;
    }
}