.RifasLoading{
    padding: 3% ;
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
}
.RifaLoading{
    width: 30%;
    height: 15rem;
    border-radius: 10px;
    border-radius: 20px;
    background: linear-gradient(90deg, var(--bg) ,var(--bg2), var(--bg2));
     background-size: 400% 400%;
     animation: gradientMove 1s linear infinite;
}
@media (max-width: 1024px){
    .RifaLoading{
        width: 100%;
        height: 13rem;
        margin-top: 1rem;
        overflow: hidden;
    }
    .RifasLoading{
        padding: 40 3% ;
      
        gap: 1rem;
    }
}