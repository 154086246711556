#swiper_container{
   width: 100%;
   height: 80vh;
}

#SwiperSlide-scroll {
    display: flex;
    justify-content: center;
 
}
#SwiperSlide-scroll img{
    width: 100%;
    object-fit: cover;
  
}

.loadingBanner{
    width: 100%;
    height: 80vh;
    background: linear-gradient(90deg, var(--bg) ,var(--bg2), var(--bg2));
    background-size: 400% 400%;
    animation: gradientMove 1s linear infinite;
    -webkit-animation: gradientMove 1s linear infinite;
}
.BannerContain{
    padding: 0% 10%;
}


#SwiperSlide-scroll {
    position: relative; /* Para que el gradiente se posicione correctamente sobre la imagen */
    width: 100%;
    height: 100%;
}

#SwiperSlide-scroll img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

#SwiperSlide-scroll::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height:110%;
    border-radius: 15px;
    background: linear-gradient(to top, rgb(0, 0, 0), rgba(0, 0, 0, 0) 90%); /* Gradiente de negro a transparente */
    z-index: 1; /* Coloca el gradiente por encima de la imagen */
}

#SwiperSlide-scroll img {
    position: relative;
    z-index: 0; /* La imagen se mantiene debajo del gradiente */
}
@media (max-width: 700px){
    .BannerContain{
        padding: 0% 0%;
    }
    #swiper_container{
    height: 23vh;
    
}
     .loadingBanner{
        height: 23vh;
    }
}