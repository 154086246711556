
@media (max-width: 700px){
    .demo{
        border-radius: 0 30px 0 0px;
        background-color: var(--bg-color);
       
    }
    .section-bg-color{
        background-color: var(--bg);
       
    }
    .espaciobg{
        height: 4rem;
        background-color: var(--bg-color);
    }
}